// extracted by mini-css-extract-plugin
export var careerWorking__btn = "dQ_GH";
export var careerWorking__btnWrapper = "dQ_GG";
export var careerWorking__card = "dQ_Gv";
export var careerWorking__cardBgDark = "dQ_Gx";
export var careerWorking__cardBgLight = "dQ_Gw";
export var careerWorking__cardDescription = "dQ_GD";
export var careerWorking__cardHorizontal = "dQ_Gy";
export var careerWorking__cardHorizontalBox = "dQ_Gz";
export var careerWorking__cardHorizontalImage = "dQ_GB";
export var careerWorking__cardImage = "dQ_GF";
export var careerWorking__cardTitle = "dQ_GC";